import React from "react";
import Layout from "../components/layout";
import { ReactComponent as Lost } from "../../static/imgs/404.svg";
import SEO from "../components/seo";
import { useIntl } from "gatsby-plugin-intl";

const NotFound = () => {
  const intl = useIntl();
  return (
    <Layout>
      <SEO lang={intl.locale} title="Error 404" />
      <div className="section blue-background galerija">
        <div className="content">
          <div className="card">
            <Lost height="auto  " width="100%" />
            <h1 style={{ margin: "6rem 0 3rem 0", textAlign: "center" }}>
              404 - Page not found!
            </h1>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default NotFound;
